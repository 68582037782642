<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="AwardIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            100% Original
          </h4>
          <p class="card-text">
            {{ $t('Intellyscale Experts are professionals in every field imaginable!') }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ClockIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
              {{ $t('14 Day Replacement') }}
          </h4>
          <p class="card-text">
            {{ $t('Are you unhappy with the Software, no worries, you will get your money back within the first 14 Days') }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ShieldIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            {{ $t('Free Support') }}
          </h4>
          <p class="card-text">
            {{ $t('Intellyscale team Support team is available Monday - Friday, 8AM - 6PM') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
