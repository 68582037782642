<template>
  <section class="app-ecommerce-details">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-card
      >
        <b-form
            class="p-1"
            @submit.stop.prevent="handleSubmit(handleSubmit)"
        >
          <b-card-body>
            <b-row>
              <b-col
                  cols="12"
                  md="5"
                  class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-img
                      :src="require('@/assets/images/illustration/personalization.svg')"
                      class="product-img"
                  />
                </div>
              </b-col>

              <b-col
                  cols="12"
                  md="7"
              >
                <label class="section-label mb-1">{{ $t('How much credits do you want to buy?') }}</label>
                <validation-provider #default="validationContext" :name="$t('Credits')" rules="required">
                  <b-input-group>
                    <b-form-input
                        :placeholder="$t('Credits')"
                        v-model="currentItem.credits"
                        type="number"
                        :state="getValidationState(validationContext)"
                        @input="totalCalculation"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </validation-provider>

                <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                  <h4 class="item-price mr-1">
                  </h4>
                  <ul class="unstyled-list list-inline pl-1 border-left">
                  </ul>
                </div>

                <div class="price-details">
                  <h6 class="price-title">

                  </h6>
                  <ul class="list-unstyled">
                    <li class="">
                      <div class=" mt-1 mb-1" v-html="creditProduct.short_description">

                      </div>
                    </li>

                  </ul>
                  <hr>
                  <ul class="list-unstyled">
                    <li class="price-detail">
                      <div class="detail-title detail-total">
                        {{ $t('Total') }}
                      </div>
                      <div class="detail-amt font-weight-bolder">
                        <p> {{ totalAmount }} Euro</p>

                      </div>
                    </li>
                  </ul>
                </div>
                <hr>

                <div class="d-flex flex-column flex-sm-row pt-1">
                  <b-button

                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      id="input-coupons"
                      type="submit"
                      class="input-group-text text-right cursor-pointer"
                      @click="addToCart()"
                  >
                    {{ $t('Add to Cart') }}
                  </b-button>

                </div>
              </b-col>
            </b-row>
          </b-card-body>

        </b-form>
      </b-card>
    </validation-observer>
  </section>
</template>

<script>

import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BInputGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, } from '@validations'
import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures
  from '@/views/admin/e-commerce/e-commerce-product-details/ECommerceProductDetailsItemFeatures'
import { useEcommerce, useEcommerceUi } from '@/views/admin/e-commerce/useEcommerce'
import eCommerceStoreModule from '@/views/admin/e-commerce/eCommerceStoreModule'
import {mapActions} from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BAlert,
    useEcommerce,
    BFormInput,
    useEcommerceUi,
    BForm,
    BInputGroup,
    required,

    // SFC
    ValidationProvider,
    ValidationObserver,
    ECommerceProductDetailsItemFeatures,
    eCommerceStoreModule,
  },

  data() {
    return {
      currentItem: {
        credits: '',
      },
      totalAmount: 0,
      creditProduct: {},
    }

  },

  mounted() {
    this.getCreditProduct()
  },

  methods: {
    ...mapActions(['GET_CART','ADD_CART_ITEM']),

    addToCart() {

      let data = {
        product_id: this.creditProduct.id,
        qty: this.currentItem.credits
      }

      this.currentItem.product_id = this.creditProduct.id
      this.ADD_CART_ITEM(data)
          .then(()=> {
            this.GET_CART();
            this.$router.push(
                {
                  name: 'credits-details',
                },
                () => setTimeout(() => {
                  this.$bvToast.toast(this.$i18n.t('Credits successfully added'), {
                    title: this.$i18n.t('Success'),
                    variant: 'success',
                    toaster: 'b-toaster-bottom-right'
                  })
                })
            )
          })
    },

    totalCalculation() {
      this.totalAmount = this.creditProduct.price * this.currentItem.credits

    },

    getCreditProduct() {
      let url = `/shop/products/1`

      this.$http.get(url)
          .then(response => {
            this.creditProduct = response.data
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  }

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
